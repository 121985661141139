import './AvailableRoomsLayout.di'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useUser } from 'src/ui/contexts/UserContext'
import { useUserLevelNotFoundWarning } from 'src/ui/hooks/useUserLevelNotFoundWarning'
import { useQueryService } from 'src/ui/hooks/useQuery'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { container } from 'src/core/Shared/_di'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { AvailableRoomsLayout } from './AvailableRoomsLayout'
import { CustomEvent } from 'src/core/Shared/infrastructure/eventsManager'
import { useEditAvailabilityActions } from 'src/ui/hooks/useEditAvailabilityActions'
import { useAsyncMutation, useSyncMutation } from 'src/ui/hooks/useMutation'
import { CouponFormType } from 'src/ui/hooks/useCouponsForm'

interface Props {
  children: ReactNode
}

export const AvailableRoomsLayoutController: FC<Props> = ({ children }) => {
  const { user, token } = useUser()
  const { modifyCoupon } = useEditAvailabilityActions()

  useUserLevelNotFoundWarning()

  const { queryUtils } = useApplicationRouter()
  const hotelId = queryUtils.getRawParam('hotel')
  const checkIn = queryUtils.getRawParam('arrive')
  const checkOut = queryUtils.getRawParam('depart')

  const [
    hotelMarketingAlertAlreadyShowed,
    setHotelMarketingAlertAlreadyShowed,
  ] = useState(false)

  const { data: hotelMarketingAlert, mutate } = useQueryService(
    'hotel-marketing-alerts',
    isDefined(hotelId) && isDefined(checkIn) && isDefined(checkOut)
      ? [hotelId, checkIn, checkOut]
      : null,
    () =>
      container.resolve('getHotelMarketingAlert')(
        hotelId!,
        checkIn!,
        checkOut!,
      ),
  )

  useEffect(() => {
    return container
      .resolve('eventsManager')
      .on(CustomEvent.SHOWED_MARKETING_ALERT, mutate)
  }, [mutate])

  // Función pública temporal para CXO que cambia el cupón promocional según el valor enviado
  useEffect(() => {
    //@ts-ignore
    window.cxoChangePromotionalCoupon = async (couponValue: string) => {
      await modifyCoupon({
        type: CouponFormType.PromotionalCode,
        value: couponValue,
      })
    }

    return () => {
      //@ts-ignore
      delete window.cxoChangePromotionalCoupon
    }
  }, [modifyCoupon])

  const onHideMarketingAlert = () => {
    container
      .resolve('sessionStorageClient')
      .set('hotelMarketingAlertAlreadyShowed', true)
    setHotelMarketingAlertAlreadyShowed(true)
  }

  const { performMutation: handleLogin } = useSyncMutation(
    'handleLogin',
    () => {
      container.resolve('login')()
    },
  )

  const { performMutation: handleLogout } = useAsyncMutation(
    'handleLogout',
    async () => {
      await container.resolve('logout')(token)
    },
  )

  return (
    <AvailableRoomsLayout
      onLogin={handleLogin}
      onLogout={handleLogout}
      user={user}
      hotelMarketingAlert={hotelMarketingAlert}
      onHideMarketingAlert={onHideMarketingAlert}
      showAlert={!hotelMarketingAlertAlreadyShowed}
    >
      {children}
    </AvailableRoomsLayout>
  )
}
