import { useState } from 'react'
import styles from './InformationTab.module.scss'
import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import {
  isDefined,
  isEmpty,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Carousel, Divider, IconText, Link, Text } from 'src/ui/components'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'
import { Image } from 'src/ui/components/atoms/Image'
import { Icon } from 'src/ui/components/atoms/Icon'
import {
  getHighlightImageLoader,
  getRoomImageLoader,
} from 'src/ui/utils/images'
import { useTrans } from 'src/ui/hooks/useTrans'
import { useCallCenter } from 'src/ui/hooks/useCallCenter'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Media } from 'src/ui/styles/objects/Media'
import { Translate } from 'src/ui/i18n/Translate'
import ChevronIcon from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import PhoneIcon from 'src/ui/components/atoms/Icon/_icons/interaction/PhoneIcon'
import SuccessIcon from 'src/ui/components/atoms/Icon/_icons/system/alert/SuccessIcon'
import { AwardsAndCertificates } from './AwardsAndCertificates'

const mockData = {
  highlightTag: {
    title: 'Highlight tag',
    icon: 'https://s7g10.scene7.com/is/image/barcelo/10212',
  },
  highlights: [
    {
      title: 'Excellent location on Paseo de la Reforma',
      icon: 'https://s7g10.scene7.com/is/image/barcelo/10013',
    },
    {
      title: 'Ideal for business and family trips',
      icon: 'https://s7g10.scene7.com/is/image/barcelo/10028',
    },
    {
      title: 'Wellness & Fitness centre plus pool',
      icon: 'https://s7g10.scene7.com/is/image/barcelo/10050',
    },
    {
      title: '4 speciality restaurants',
      icon: 'https://s7g10.scene7.com/is/image/barcelo/10002',
    },
    {
      title: '4 speciality restaurants',
      icon: 'https://s7g10.scene7.com/is/image/barcelo/10092',
    },
    {
      title:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti cupiditate ex repellendus architecto officiis praesentium molestiae, ipsam, tenetur est, eum debitis delectus quam quod illum eligendi atque quidem eos aliquam.',
      icon: 'https://s7g10.scene7.com/is/image/barcelo/10166',
    },
  ],
  imageSlides: [
    {
      src: 'https://s7g10.scene7.com/is/image/barcelo/OXCA_VIEW_19',
      alt: 'img1',
    },
    {
      src: 'https://s7g10.scene7.com/is/image/barcelo/OXCA_VIEW_19',
      alt: 'img2',
    },
    {
      src: 'https://s7g10.scene7.com/is/image/barcelo/OXCA_VIEW_19',
      alt: 'img3',
    },
    {
      src: 'https://s7g10.scene7.com/is/image/barcelo/OXCA_VIEW_19',
      alt: 'img4',
    },
    {
      src: 'https://s7g10.scene7.com/is/image/barcelo/OXCA_VIEW_19',
      alt: 'img5',
    },
  ],
  checkIn: 'Desde las 14:00 h',
  checkOut: 'Desde las 12:00 h',
  benefits: [
    { title: 'Mejor precio online garantizado' },
    { title: 'Últimas habitaciones disponibles' },
    { title: 'Pre check-in online' },
    { title: 'Pre check-in online' },
    { title: 'Pre check-in online' },
  ],
  awards: [
    {
      title: 'Award title 1',
      description: 'Award description 1',
      logoPath:
        'https://www.barcelo.com/content/dam/barcelo/commons/icons/highlights-hoteles/204.svg',
    },
    {
      title: 'Award title 2',
      description:
        'Award description 2: Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo temporibus vitae consectetur sapiente dignissimos voluptates deserunt, reiciendis rem commodi? Earum consequuntur ratione fugiat, sint deserunt repudiandae ab saepe nostrum veritatis!',
      logoPath:
        'https://www.barcelo.com/content/dam/barcelo/commons/icons/wedding-weather/globe.svg',
    },
    {
      title: 'Award title 3',
      description:
        'Award description 3: Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo temporibus vitae consectetur sapiente dignissimos voluptates deserunt, reiciendis rem commodi? Earum consequuntur ratione fugiat, sint deserunt repudiandae ab saepe nostrum veritatis!',
      logoPath:
        'https://www.barcelo.com/content/dam/barcelo/commons/icons/highlights-rooms/10094.svg',
    },
  ],
  certificates: [
    {
      title: 'Certificate title 1',
      description:
        'Certificate description 1: Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo temporibus vitae consectetur sapiente dignissimos voluptates deserunt, reiciendis rem commodi? Earum consequuntur ratione fugiat, sint deserunt repudiandae ab saepe nostrum veritatis!',
      logoPath:
        'https://www.barcelo.com/content/dam/barcelo/commons/icons/highlights-hoteles/204.svg',
    },
  ],
}

interface Props {
  hotel: Hotel
}

export const InformationTab = ({ hotel }: Props) => {
  const { trans } = useTrans(['common'])
  const [hasToShowAllBenefits, setHasToShowAllBenefits] = useState(false)
  const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
    useCallCenter()

  const hasHighlightTag =
    isDefined(mockData.highlightTag) && !isEmpty(mockData.highlightTag)
  const hasHighlights =
    isDefined(mockData.highlights) && !isEmpty(mockData.highlights)
  const hasBenefits =
    isDefined(mockData.benefits) && mockData.benefits.length > 0
  const hasCertificates =
    isDefined(mockData.certificates) && !isEmpty(mockData.certificates)
  const hasAwards = isDefined(mockData.awards) && !isEmpty(mockData.awards)
  const hasMoreThanThreeBenefits = mockData.benefits.length > 3
  const benefitsToShow =
    hasBenefits && hasToShowAllBenefits
      ? mockData.benefits
      : mockData.benefits.slice(0, 3)

  return (
    <div>
      <Carousel
        className={styles.carousel}
        imageSlides={mockData.imageSlides}
        alt="Carrousel de imágenes del hotel"
        loader={getRoomImageLoader({
          aspectRatio: { width: 16, height: 9 },
        })}
        hasToShowFullScreenButton={false}
      />

      <div className={styles.details}>
        <div className={styles.hotelDetail}>
          {hasHighlightTag && (
            <Flex
              justifyContent="center"
              alignItems="center"
              gap="xxs"
              className={styles.highlightTag}
            >
              <Image
                alt={mockData.highlightTag.title}
                src={mockData.highlightTag.icon}
                width={20}
                height={20}
                quality={75}
                loader={getHighlightImageLoader}
              />
              <Text
                fontStyle={{ mobile: 's-500', laptop: 'm-500' }}
                color="dark"
                className={styles.serviceTitle}
              >
                {mockData.highlightTag.title}
              </Text>
            </Flex>
          )}
          <Flex direction="column" gap="xs">
            <Text fontStyle="l-500">
              {trans('aside-modal-tab_hotel-info_detail-title')}
            </Text>
            <Text fontStyle="m-300">
              {trans('aside-modal-tab_hotel-info_detail-subtitle')}
            </Text>
          </Flex>
          {hasHighlights && (
            <div className={styles.servicesContainer}>
              {mockData.highlights?.map((highlight, index) => (
                <Flex
                  key={index}
                  direction="column"
                  alignItems="center"
                  gap="xs"
                  className={styles.service}
                >
                  <Image
                    alt={highlight.title}
                    src={highlight.icon}
                    width={24}
                    height={24}
                    quality={75}
                    loader={getHighlightImageLoader}
                  />
                  <Text
                    fontStyle="s-300"
                    color="dark"
                    className={styles.serviceTitle}
                    title={highlight.title}
                  >
                    {highlight.title}
                  </Text>
                </Flex>
              ))}
            </div>
          )}
          <Flex direction="row">
            <Flex direction="column" gap="xxs">
              <Text fontStyle="m-500">{trans('check-in_title')}</Text>
              <Text fontStyle="s-300">{mockData.checkIn}</Text>
            </Flex>
            <Divider dir="vertical" className={styles.divider} />
            <Flex direction="column" gap="xxs">
              <Text fontStyle="m-500">{trans('check-out_title')}</Text>
              <Text fontStyle="s-300">{mockData.checkOut}</Text>
            </Flex>
          </Flex>
        </div>

        {hasBenefits && (
          <>
            <Divider dir="horizontal" className={styles.horizontalDivider} />
            <div className={styles.benefits}>
              <Text fontStyle="l-500">{trans('hotel-promotions_title')}</Text>
              {benefitsToShow.map((benefit, index) => (
                <Flex gap="xs" key={index}>
                  <Icon icon={SuccessIcon} size="s" className={styles.icon} />
                  <Text fontStyle="m-300">{benefit.title}</Text>
                </Flex>
              ))}
              {hasMoreThanThreeBenefits && (
                <ButtonLink
                  className="touch-security-zone-always"
                  aria-label={trans(
                    hasToShowAllBenefits
                      ? 'drop-down_show-less'
                      : 'drop-down_show-more',
                  )}
                  onClick={event => {
                    event.preventDefault()
                    setHasToShowAllBenefits(!hasToShowAllBenefits)
                  }}
                >
                  <Flex>
                    <Text fontStyle="m-500">
                      {trans(
                        hasToShowAllBenefits
                          ? 'drop-down_show-less'
                          : 'drop-down_show-more',
                      )}
                    </Text>
                    <Icon
                      icon={ChevronIcon}
                      size="l"
                      color="button-secondary-enabled"
                      rotate={hasToShowAllBenefits ? 270 : 90}
                    />
                  </Flex>
                </ButtonLink>
              )}
            </div>
          </>
        )}

        {(hasCertificates || hasAwards) && (
          <>
            <Divider dir="horizontal" className={styles.horizontalDivider} />
            <AwardsAndCertificates
              awards={mockData.awards}
              certificates={mockData.certificates}
            />
          </>
        )}

        <Divider dir="horizontal" className={styles.horizontalDivider} />

        <div>
          <Flex direction="column" gap="xs">
            <Text fontStyle="l-500">{trans('call-center_hero-title')}</Text>

            <Media mobile tablet>
              <Text fontStyle="m-300">
                {trans('call-center_hero-subtitle-mobile')}
              </Text>

              <Link
                link={`tel:${callCenterPhone}`}
                variant="simple"
                fontStyle="button"
                className={styles.callCenterPhone}
                data-data-phone={callCenterCountryCode}
                target={'_self'}
              >
                <IconText
                  icon={PhoneIcon}
                  iconSize="l"
                  iconColor="button-secondary-enabled"
                  text={`${callCenterPhone}${callCenterFreePhone}`}
                  fontStyle="m-700"
                  fontColor="primary"
                  gap="s"
                />
              </Link>
            </Media>

            <Media desktop laptop>
              <div>
                <Translate
                  i18nKey="common:call-center_hero-subtitle-desktop"
                  values={{
                    callCenterPhone: `${callCenterPhone}${callCenterFreePhone}`,
                  }}
                  components={{
                    regular: <Text fontStyle="m-300" />,
                    bold: (
                      <Text
                        data-data-phone={callCenterCountryCode}
                        fontStyle="m-700"
                      />
                    ),
                  }}
                />
              </div>
            </Media>
          </Flex>
        </div>
      </div>
    </div>
  )
}
