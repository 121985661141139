import { Image } from 'src/ui/components/atoms/Image'
import { IconText } from 'src/ui/components'
import styles from './NearbyHotelCard.module.scss'
import { LocationIcon } from 'src/ui/components/atoms/Icon/_icons/general/LocationIcon'
import { TripadvisorRatings } from 'src/ui/views/_components/TripadvisorRatings'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Footer } from './Footer'
import { getHotelImageLoader } from 'src/ui/utils/images'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { FC } from 'react'
import { Hotel, NearbyHotel } from 'src/core/Hotel/domain/Hotel.model'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'

interface Props {
  id: NearbyHotel['id']
  bestPriceAverage: NearbyHotel['bestPriceAverage']
  hotel: Hotel | undefined
  onReserve: (hotelId: string) => void
}

export const NearbyHotelCard: FC<Props> = ({
  id,
  bestPriceAverage,
  hotel,
  onReserve,
}) => {
  if (isUndefined(hotel)) {
    return null
  }

  function onHotelTitleClick() {
    onReserve(id)
  }

  return (
    <div className={styles.hotelCardContainer}>
      <Flex alignItems="stretch" direction="column">
        <div className={styles.hotelImageContainer}>
          <Image
            className={styles.hotelImage}
            alt={hotel.name}
            src={hotel.image}
            fill
            quality={75}
            loader={getHotelImageLoader}
          />
        </div>
        <div className={styles.hotelInfo}>
          <ButtonLink onClick={onHotelTitleClick} className={styles.hotelName}>
            {hotel.name}
          </ButtonLink>
          <IconText
            icon={LocationIcon}
            iconSize="s"
            iconColor="icon-dark"
            text={hotel.destination}
            fontStyle="s-500"
            fontColor="dark"
            gap="xs"
          />
          <TripadvisorRatings
            gap="small"
            reviews={hotel.reviews}
            showRankLevelName={false}
          />
        </div>
      </Flex>
      <Footer
        bestPriceAverage={bestPriceAverage}
        id={id}
        onReserve={onReserve}
      />
    </div>
  )
}
