import { FC } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import styles from './DotRatings.module.scss'
import { getReviewsAverageDotsType } from 'src/core/Hotel/domain/getReviewsAverageDotsType'
import { useTrans } from 'src/ui/hooks/useTrans'
import { IconSize, SpaceType } from 'src/ui/styles/settings'

interface Props {
  ratings?: string
  size?: 'small' | 'medium'
}

export const DotRatings: FC<Props> = ({ ratings, size = 'small' }) => {
  const averageDotsType = getReviewsAverageDotsType(ratings)

  const { trans } = useTrans(['new-reservation'])

  return (
    <div
      className={classNames(
        styles.dotWrapper,
        size === 'medium' && styles.isMedium,
        size === 'small' && styles.isSmall,
      )}
      aria-label={trans('tripadvisor_average-aria-label', {
        rating: ratings,
      })}
    >
      {averageDotsType.map((dotType, i) => (
        <span
          key={i}
          className={classNames(
            styles.dot,
            styles[dotType],
            size === 'medium' && styles.isMedium,
            size === 'small' && styles.isSmall,
          )}
        ></span>
      ))}
    </div>
  )
}
