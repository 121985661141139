import { FC, useEffect, useState } from 'react'
import styles from './AwardsAndCertificates.module.scss'
import { Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { classNames } from 'src/ui/utils/classnames'
import {
  isDefined,
  isEmpty,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CarouselDesktop } from './CarouselDesktop'
import { CarouselMobile } from './CarouselMobile'
import { useMedia } from 'src/ui/hooks/useMedia'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { useTrans } from 'src/ui/hooks/useTrans'
import { AwardsAndCertificates as AwardsAndCertificatesType } from 'src/core/Hotel/domain/Hotel.model'

interface Props {
  awards: AwardsAndCertificatesType[]
  certificates: AwardsAndCertificatesType[]
}

export const AwardsAndCertificates: FC<Props> = ({ awards, certificates }) => {
  const { trans } = useTrans(['common'])
  const { media } = useMedia()
  const languageConfig = useLanguageConfig()
  const [selectedTag, setSelectedTag] = useState<AwardsAndCertificatesType[]>(
    [],
  )
  const [hasToShowNavigationArrows, sethasToShowNavigationArrows] =
    useState(false)

  const hasCertificates = isDefined(certificates) && !isEmpty(certificates)
  const hasAwards = isDefined(awards) && !isEmpty(awards)
  const isMobileOrTablet = media === 'mobile' || media === 'tablet'
  const alt =
    selectedTag === awards
      ? trans('aside-modal-tab_hotel-info_awards-tag')
      : trans('aside-modal-tab_hotel-info_certificates-tag')
  const isRTL = languageConfig.properties.isRTL

  useEffect(() => {
    if (!hasAwards && !hasCertificates) {
      return
    }

    hasAwards ? setSelectedTag(awards) : setSelectedTag(certificates)
  }, [hasAwards, hasCertificates, awards, certificates])

  useEffect(() => {
    sethasToShowNavigationArrows(selectedTag.length > 1)
  }, [selectedTag])

  return (
    <>
      <Flex direction="column" gap="xs">
        <Text fontStyle="l-500">
          {trans('aside-modal-tab_hotel-info_awards-and-certificates-title')}
        </Text>
        <Text
          fontStyle="m-300"
          className="mb-mobile-m mb-tablet-m mb-laptop-l mb-desktop-l"
        >
          {trans('aside-modal-tab_hotel-info_awards-and-certificates-subtitle')}
        </Text>
      </Flex>
      <Flex
        gap="m"
        className={classNames(
          hasToShowNavigationArrows && styles.tagButtonContainer,
        )}
      >
        {hasAwards && (
          <button
            type="button"
            className={classNames(
              styles.tagButton,
              selectedTag === awards && styles.isSelected,
            )}
            onClick={() => setSelectedTag(awards)}
          >
            {trans('aside-modal-tab_hotel-info_awards-tag')}
          </button>
        )}
        {hasCertificates && (
          <button
            type="button"
            className={classNames(
              styles.tagButton,
              selectedTag === certificates && styles.isSelected,
            )}
            onClick={() => setSelectedTag(certificates)}
          >
            {trans('aside-modal-tab_hotel-info_certificates-tag')}
          </button>
        )}
      </Flex>

      {isMobileOrTablet ? (
        <CarouselMobile
          item={selectedTag}
          alt={alt}
          isRTL={isRTL}
          hasToShowNavigationArrows={hasToShowNavigationArrows}
        />
      ) : (
        <CarouselDesktop
          item={selectedTag}
          alt={alt}
          isRTL={isRTL}
          hasToShowNavigationArrows={hasToShowNavigationArrows}
        />
      )}
    </>
  )
}
