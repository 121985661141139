import { useModal } from 'src/ui/hooks/useModal'
import { DatesModal } from 'src/ui/views/_components/DatesModal'
import { DatesModal as DatesModalV2 } from 'src/ui/views/_components/DatesModalV2'
import { useAvailabilityCriteria } from 'src/ui/views/AvailableRooms/useAvailabilityCriteria'
import { useEditAvailabilityActions } from 'src/ui/hooks/useEditAvailabilityActions'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { AvailabilityError } from 'src/core/Availability/domain/AvailabilityError'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'

export const useDatesModal = () => {
  const { showModal, hideModal } = useModal(DatesModal)
  const { showModal: showModalV2, hideModal: hideModalV2 } =
    useModal(DatesModalV2)
  const { availabilityCriteria } = useAvailabilityCriteria()
  const { modifyDates } = useEditAvailabilityActions()
  const { error } = useAvailability()
  const isNewCalendarEnabled = isFeatureFlagEnabled(
    'FF_FEATURE_5205_CALENDAR_RESTRICTIONS',
  )

  const showDatesModal = () => {
    if (isUndefined(availabilityCriteria)) {
      return
    }

    if (isNewCalendarEnabled) {
      showModalV2({
        checkIn: availabilityCriteria.checkIn,
        checkOut: availabilityCriteria.checkOut,
        onSubmit: modifyDates,
        onClose: hideModalV2,
        initialError: AvailabilityError.isHotelAvailabilityNotFoundError(error),
      })
    } else {
      showModal({
        checkIn: availabilityCriteria.checkIn,
        checkOut: availabilityCriteria.checkOut,
        onSubmit: modifyDates,
        onClose: hideModal,
        initialError: AvailabilityError.isHotelAvailabilityNotFoundError(error),
      })
    }
  }

  return { showDatesModal }
}
