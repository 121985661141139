import styles from './HotelPromotions.module.scss'
import { Skeleton, Text } from 'src/ui/components'
import { isNotEmptyString } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Promotion } from 'src/core/Hotel/domain/Promotion.model'
import { FC } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import SuccessIcon from 'src/ui/components/atoms/Icon/_icons/system/alert/SuccessIcon'

interface Props {
  hotelPromotions: Promotion[]
  isLoadingPromotions?: boolean
  showAll?: boolean
}

export const HotelPromotions: FC<Props> = ({
  hotelPromotions,
  isLoadingPromotions = false,
  showAll = false,
}) => {
  const allPromotions = hotelPromotions?.reduce((acc, promotion) => {
    acc.push(...promotion.description.split('- ').filter(isNotEmptyString))
    return acc
  }, [] as string[])

  const promotions = showAll ? allPromotions : allPromotions?.slice(0, 2)

  return (
    <ul className={styles.list}>
      {promotions.map((promotion, index) => (
        <li
          className={classNames(
            styles.promotion,
            !showAll && styles.withEllipsis,
          )}
          key={index}
        >
          {isLoadingPromotions ? (
            <Skeleton width="100%" height={20} />
          ) : (
            <Flex gap="xs">
              <Icon icon={SuccessIcon} size="s" className={styles.icon} />
              <Text fontStyle="m-300">{promotion}</Text>
            </Flex>
          )}
        </li>
      ))}
    </ul>
  )
}
