import styles from './FastBookingDesktop.module.scss'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Image } from 'src/ui/components/atoms/Image'
import { getHotelImageLoader } from 'src/ui/utils/images'
import { Text } from 'src/ui/components/atoms/Text'
import { FC } from 'react'
import { useTrans } from 'src/ui/hooks/useTrans'
import { TripadvisorRatings } from 'src/ui/views/_components/TripadvisorRatings'
import { Flex } from 'src/ui/styles/objects/Flex'
import { HotelPromotionBanner } from '../HotelPromotionBanner/HotelPromotionBanner'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'
import PencilIcon from 'src/ui/components/atoms/Icon/_icons/interaction/PencilIcon'
import SuccessIcon from 'src/ui/components/atoms/Icon/_icons/system/alert/SuccessIcon'
import { useHotelFastBooking } from '../useHotelFastBooking'
import CalendarIcon from 'src/ui/components/atoms/Icon/_icons/general/CalendarIcon'
import BedIcon from 'src/ui/components/atoms/Icon/_icons/general/BedIcon'
import { FastBookingDesktopSkeleton } from './FastBookingDesktopSkeleton'
import ErrorFilledIcon from 'src/ui/components/atoms/Icon/_icons/system/alert/ErrorFilledIcon'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'

interface Props {
  onModifyOccupancy: () => void
  onModifyDates: () => void
  onModifyCoupon: () => void
  onHotelDetailsClick: () => void
}

export const FastBookingDesktop: FC<Props> = ({
  onModifyOccupancy,
  onModifyDates,
  onModifyCoupon,
  onHotelDetailsClick,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const {
    hotel,
    availabilityCriteria,
    hasDestination,
    hasHotelAvailabilityNotFoundError,
    nights,
    guestResume,
    getCouponLinkText,
    isCouponApplied,
  } = useHotelFastBooking()

  const isHotelDetailModalOpenable = isFeatureFlagEnabled(
    'FF_FEATURE_5197_HOTEL_DETAIL_MODAL',
  )

  if (isUndefined(hotel) || isUndefined(availabilityCriteria)) {
    return <FastBookingDesktopSkeleton />
  }

  return (
    <div className={styles.container}>
      <Image
        alt={hotel.name}
        src={hotel.image}
        aria-hidden="true"
        height={216}
        width={286}
        quality={75}
        loader={getHotelImageLoader}
        priority={true}
      />
      <div className={styles.dataWrapper}>
        <Flex justifyContent="space-between">
          <Text as="h2" fontStyle="xl-700">
            {hotel.name}
          </Text>
          <HotelPromotionBanner />
        </Flex>

        <Flex>
          <Flex direction="column" className="w-5/12">
            {hasDestination && (
              <Text as="p" fontStyle="m-300" className={styles.heroLineData}>
                {hotel.destination}
              </Text>
            )}
            <TripadvisorRatings
              gap="small"
              reviews={hotel.reviews}
              className={styles.heroLineData}
            />
            {isHotelDetailModalOpenable && (
              <ButtonLink
                onClick={onHotelDetailsClick}
                className={styles.heroLineData}
              >
                {trans('availability_hero_see-details')}
              </ButtonLink>
            )}
          </Flex>

          <div className={styles.verticalDivider}></div>

          <Flex direction="column" className="w-7/12">
            <div className={styles.heroLineDataWrapper}>
              <div className={styles.heroLineData}>
                {hasHotelAvailabilityNotFoundError ? (
                  <Icon
                    size="l"
                    color="support-error"
                    icon={ErrorFilledIcon}
                    testId="cancel-icon"
                    className="mr-xs"
                  />
                ) : (
                  <Icon size="l" icon={CalendarIcon} className="mr-xs" />
                )}
                <span className={styles.heroLineEllipsisText}>
                  <Text fontStyle="m-500">
                    <span className={styles.datesRange}>
                      {datesManager.formatDateRangeToLocaleWithYearAndWeekDay({
                        checkIn: availabilityCriteria.checkIn,
                        checkOut: availabilityCriteria.checkOut,
                      })}
                      . &nbsp;
                    </span>
                    <span>{nights}</span>
                  </Text>
                </span>
              </div>
              <ButtonLink
                className={styles.editButton}
                onClick={onModifyDates}
                data-testid="edit-dates"
                aria-label={trans('availability_hero_edit-dates-label')}
              >
                {trans('availability_hero_edit')}
              </ButtonLink>
            </div>

            <div className={styles.heroLineDataWrapper}>
              <div className={styles.heroLineData}>
                <Icon size="l" icon={BedIcon} className="mr-xs" />
                <span className={styles.heroLineEllipsisText}>
                  {guestResume}
                </span>
              </div>
              <ButtonLink
                className={styles.editButton}
                onClick={onModifyOccupancy}
                data-testid="edit-guest"
                aria-label={trans('availability_hero_edit-guest-label')}
              >
                {trans('availability_hero_edit')}
              </ButtonLink>
            </div>

            <Flex
              gap="xs"
              alignItems="center"
              className="w-full touch-security-zone-always"
            >
              <button
                onClick={onModifyCoupon}
                data-testid="edit-coupon"
                className="flex items-center gap-xs cursor-pointer"
              >
                <Text as="span" fontStyle="m-500" className={styles.couponLink}>
                  {getCouponLinkText()}
                </Text>
                {isCouponApplied && <Icon icon={PencilIcon} size="m" />}
              </button>

              {isCouponApplied && (
                <>
                  <div className="grow">&nbsp;</div>
                  <Flex className="shrink-0" alignItems="center">
                    <Text fontStyle="s-500" color="support-success">
                      {trans('availability_hero_coupon-added')}
                    </Text>
                    <Icon icon={SuccessIcon} size="s" className="ml-xs" />
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </div>
    </div>
  )
}
